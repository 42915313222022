/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { appImages, appRoute } from '@utils/globalConstant';
import { Link } from '@core/Inputs';

import './index.scss';
import { UTAG_LINK } from '@utils/utag';

export function Footer() {
  const { t } = useStaticContent();

  return (
    <>
      <footer className="container">
        <div className="row">
          <div className="col-sm-12">
            {/* REMOVE all nav links from the footer */}
            {/* <ul className="d-lg-flex d-grid gap-2 justify-content-between list-inline body-medium-bold mt-3 mt-sm-5 my-lg-5">
              <li
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.FOOTER_ON_CLICK.IMPRINT_ON_CLICK);
                  }
                }}
              >
                <Link className="primary-color-text text-decoration-none" to={appRoute.IMPRINT}>
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_footer_navi_h1') }} />
                </Link>
              </li>
              <li
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.FOOTER_ON_CLICK.AGB_ON_CLICK);
                  }
                }}
              >
                <Link className="primary-color-text text-decoration-none" to={appRoute.AGB}>
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_footer_navi_h2') }} />
                </Link>
              </li>
              <li
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.FOOTER_ON_CLICK.CONSUM_INFO_ON_CLICK);
                  }
                }}
              >
                <Link className="primary-color-text text-decoration-none" to={appRoute.CUS_INFO}>
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_footer_navi_h3') }} />
                </Link>
              </li>
              <li
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.FOOTER_ON_CLICK.PRIVACY_ON_CLICK);
                  }
                }}
              >
                <Link className="primary-color-text text-decoration-none" to={appRoute.DATA_PROT}>
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_footer_navi_h4') }} />
                </Link>
              </li>
              <li
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.FOOTER_ON_CLICK.WIDERRUF_ON_CLICK);
                  }
                }}
              >
                <Link className="primary-color-text text-decoration-none" to={appRoute.REVOCATION}>
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_footer_navi_h5') }} />
                </Link>
              </li>
              <li
                onClick={() => {
                  if (window && window.utag) {
                    window.utag.link(UTAG_LINK.FOOTER_ON_CLICK.CANCEL_CONTRACT_ON_CLICK);
                  }
                }}
              >
                <Link
                  className="primary-color-text text-decoration-none"
                  to={appRoute.CANCEL_CONTRACT}
                >
                  <div dangerouslySetInnerHTML={{ __html: t('sh_web_footer_navi_h6') }} />
                </Link>
              </li>
            </ul> */}

            <ul className="list-inline d-grid gap-2 d-lg-flex justify-content-between mt-4 my-lg-4 align-items-center">
              <li
                className="body-large-regular primary-color-text mt-3 mt-lg-0"
                dangerouslySetInnerHTML={{ __html: t('sh_web_footer_copyright_text') }}
              />
              <li className="d-none d-lg-flex flex-row align-items-center justify-content-center">
                <span className="">
                  <img src={appImages.congstarLogoPoweredBy} alt="Congstar" />
                </span>
              </li>
              <li className="d-flex d-lg-none flex-row align-items-center justify-content-start">
                <span className="">
                  <img
                    className="responsive-logo"
                    src={appImages.congstarResponsiveLogoPoweredBy}
                    alt="Congstar"
                  />
                </span>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
